import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import CustomPopup from "components/shared/modal/customPopup";

import { pushGoogleTabEvent, createHomeArticlePayload } from "api/googletab";
import { useRouter } from "next/router";

const NewsImageBanner = ({ postData }) => {
  // console.log("In newsImageBanner", postData);
  //const sessionData = JSON.parse(localStorage.getItem("lallantopSession2"));
  const router = useRouter();
  const { query } = router;
  // const { data: session } = useSession();
  // const { userLocalDetail } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loginPrompt, setLoginPrompt] = useState(false);
  // const [videoDuration, setVideoDuration] = useState(0);
  const [postStateData, setPostStateData] = useState(postData);
  // const [openShareModal, setOpenShareModal] = useState(false);
  // const [shareData, setShareData] = useState({});
  const { postDetails } = useSelector(({ PostReducer }) => PostReducer);
  const isHomePath = router.asPath === "/";

  const pageToOpen = `/${
    postStateData?.categorySlug?.toLowerCase() || query?.categoryName
  }/post/${postStateData?.postSlug || ""}`;

  // const shareUrl = `${pageToOpen}?postId=${postStateData?._id}`;
  useEffect(() => {
    if (postDetails?._id === postData?._id) {
      setPostStateData(postDetails);
    }
  }, [postDetails, postData?._id]);

  console.log("live blgo data ", postData);

  return (
    <div
      className="newsImageBanner"
      style={{ width: "inherit", height: "auto", marginBottom: "14px" }}
    >
      <a
        href={`${
          postData.eventStatus === undefined
            ? pageToOpen
            : `/business/post/${postData?.sefUrl}`
        }`}
        passHref
      >
        <a
          className="headline-thumb"
          onClick={() => {
            if (isHomePath) {
              pushGoogleTabEvent(
                "lt_cards",
                createHomeArticlePayload(postStateData, { Section: "Home" })
              );
            } else if (router.asPath === "/video") {
              // console.log("bdeio");
              pushGoogleTabEvent(
                "lt_cards",
                createHomeArticlePayload(postStateData, {
                  Section: "Video",
                })
              );
            }
          }}
        >
          <img
            src={
              postData.thumbnail !== undefined
                ? postData?.thumbnail + "?width=360&quality=50"
                : postStateData?.headingImageUrl + "?width=360&quality=50"
            }
            alt={postStateData?.imgAltText || "News pic"}
            width="100%"
            height={"inherit"}
            className="newsImageBannerImage"
            // eslint-disable-next-line react/no-unknown-property
            fetchPriority="high"
            //loading="lazy"
          />
          {postData.type === "liveblog" &&
            postData?.liveBlogData?.eventStatus === "live" && (
              <div style={{ position: "absolute", top: "-15px", right: "0" }}>
                <img
                  src="assets/images/giphy.gif"
                  width="60px"
                  height="80px"
                  alt="live"
                ></img>
              </div>
            )}
          <div className="image-content image-content-fallback">
            <div className="news-type-icon" style={{ color: "white" }}>
              {postData.eventStatus === undefined && (
                <i
                  className={`icon-${
                    postStateData?.type === "text" ||
                    postData.type === "liveblog"
                      ? "cat-read"
                      : postStateData?.type === "audio"
                      ? "mic"
                      : "play-circle"
                  }`}
                ></i>
              )}
            </div>
            <div className="heading">
              <a
                href={pageToOpen}
                style={{
                  fontFamily:
                    "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "white",
                  maxHeight: "7.5rem",
                }}
                onCustomClick={() => {
                  if (isHomePath) {
                    // ClevertapReact.event(
                    //   "homepage_card",
                    //   createHomeCardPayload("homepage_card", postStateData)
                    // );
                  }
                }}
              >
                <h2
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    marginBottom: "0rem",
                  }}
                >
                  {postStateData?.title?.trim()}
                </h2>
              </a>
            </div>
          </div>
        </a>
      </a>

      <CustomPopup
        showModal={loginPrompt}
        onClose={() => setLoginPrompt(false)}
      />
    </div>
  );
};
export default NewsImageBanner;
